import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { JourneyResource, ResourceType } from "../../../../types/serializers";
import useCreateCallback from "../../../hooks/useCreateCallback";
import useResource from "../../../hooks/useResource";
import { CallbackSuccessHandler } from "../../../hooks/useResourceCallback";
import { trackEvent } from "../../../lib/analytics";
import { StatemachineContext } from "../contexts/StatemachineContext";
import { JourneyTrigger } from "../types";

const useStartJourney = (
  id?: number
): [
  callback: (trigger: JourneyTrigger) => () => void,
  loading: boolean,
  error?: string
] => {
  const { t } = useTranslation();
  const {
    context,
    journeyReferer,
    currentJourney,
    error,
    loading,
    onNewJourney,
    sessionId,
    setCurrentJourneyId,
    setError,
    setLoading,
    testMode,
    wizardId,
  } = useContext(StatemachineContext);
  const wizard = useResource(
    {
      id: wizardId?.toString(),
      type: ResourceType.Wizard,
    },
    { root: GLOBALS.statemachineRoot }
  );
  const [createCallback] = useCreateCallback(
    ResourceType.Journey,
    GLOBALS.statemachineRoot
  );

  const handleError = () => {
    setError(t("shared:statemachine.errorMessage")!);
    setLoading(false);
  };
  const handleSuccess: CallbackSuccessHandler = ({ json }) => {
    trackEvent("question_asked", {
      question_asked: wizard?.attributes.question,
      wizard_id: wizardId,
    });

    if (json.data) {
      setCurrentJourneyId(json.data.id);

      if (onNewJourney) {
        onNewJourney(json.data as JourneyResource);
      }
    }
    setLoading(false);
  };

  const callback = (trigger: JourneyTrigger) => () => {
    if (id) {
      setError(undefined);
      setLoading(true);

      createCallback(
        {
          context,
          previous_journey_id: currentJourney?.id,
          referer: journeyReferer,
          session_id: sessionId,
          test_mode: testMode,
          trigger: trigger,
          wizard_id: id,
        },
        handleSuccess,
        handleError
      );
    } else {
      setError("No slug given");
    }
  };

  return [callback, loading, error];
};

export default useStartJourney;
