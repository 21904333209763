import {
  combineReferrers,
  useCurrentReferrer,
} from "../../../hooks/useCombinedReferer";

const useLinkTransformer = (referer: string) => {
  const currentReferrer = useCurrentReferrer();

  return (url: string) => {
    if (url.startsWith(`${GLOBALS.root}/statemachine?`)) {
      const parsed = new URL(url);
      if (parsed.search) {
        const params = new URLSearchParams(parsed.search);
        params.set("referer", combineReferrers(currentReferrer, referer));
        parsed.search = params.toString();
      }

      return parsed.toString();
    }

    return url;
  };
};

export default useLinkTransformer;
