import { useContext } from "react";

import { AppContext } from "../components/appContext/AppContextProvider";

import useIsMounted from "./useIsMounted";

export const combineReferrers = (
  ...referers: Array<string | undefined | null>
) => referers.filter(Boolean).join("_");

export const useCurrentReferrer = () => {
  const { referer } = useContext(AppContext);

  return referer;
};

const useCombinedReferer = (additionalReferer?: string) => {
  const mounted = useIsMounted();
  const currentReferrer = useCurrentReferrer();

  if (!mounted) {
    // Fix issue where browser result differs from server side rendered result
    // because of missing query param during server side rendering.
    return additionalReferer;
  }

  return combineReferrers(currentReferrer, additionalReferer);
};

export default useCombinedReferer;
