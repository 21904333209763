import React from "react";

import BDChecker from "../../../bdChecker/BDChecker";
import Button from "../../../button";
import { ActionProps } from "../Action";

interface Props extends ActionProps {
  active?: boolean;
  bdCheckerUrl?: string;
  buttonId: number;
  name: string;
}

const ActionButton = ({
  active,
  bdCheckerUrl,
  buttonId,
  name,
  loading,
  sendUserResponse,
  step,
}: Props) => {
  if (bdCheckerUrl) {
    const handleBdResult = (value: string) => {
      const button = step.attributes.node_answer_buttons.find(
        (btn) => btn.value === value && !btn.bd_checker_url
      );
      if (button) {
        sendUserResponse(button.id.toString());
      }
    };

    return (
      <BDChecker label={name} url={bdCheckerUrl} onFinish={handleBdResult} />
    );
  }

  const buttonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    sendUserResponse(buttonId.toString());
  };

  return (
    <Button
      height="auto"
      isDisabled={loading}
      justifyContent="flex-start"
      lineHeight="base"
      marginBottom="0.5em"
      minHeight={10}
      textAlign="left"
      variant={active ? "solid" : "outline"}
      whiteSpace="normal"
      width="100%"
      onClick={buttonHandler}
    >
      {name}
    </Button>
  );
};

export default ActionButton;
