import { FormControl, Input, Stack, chakra } from "@chakra-ui/react";
import React, { ChangeEvent, FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "../../../button";
import { ActionProps } from "../Action";

export const ACTION_INPUT_TID = "ActionInput";

interface ActionInputProps extends ActionProps {
  inputType: string;
}

const ActionInput = ({
  sendUserResponse,
  inputType,
  loading,
  step,
}: ActionInputProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(
    step.attributes.answer.value?.toString() ?? ""
  );
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    sendUserResponse(value);
  };
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setValue(e.target.value);
  };

  return (
    <chakra.form width="100%" onSubmit={handleSubmit}>
      <Stack alignItems="baseline" flexDirection="row">
        <FormControl pr={3}>
          <Input
            data-testid={ACTION_INPUT_TID}
            type={inputType}
            value={value}
            onChange={handleChange}
          />
        </FormControl>
        <Button
          isDisabled={loading}
          minWidth="60px"
          type="submit"
          value="Submit"
        >
          {t("shared:statemachine.confirm")}
        </Button>
      </Stack>
    </chakra.form>
  );
};

export default ActionInput;
