import { VStack } from "@chakra-ui/react";
import React from "react";

import { ActionProps } from "../Action";

import ActionButton from "./ActionButton";

const ActionList = ({ loading, sendUserResponse, step }: ActionProps) => {
  return (
    <VStack gap="0.1em" width="100%">
      {step.attributes.node_answer_buttons.map((option) => (
        <ActionButton
          active={option.id === step.attributes.answer.value}
          bdCheckerUrl={option.bd_checker_url}
          buttonId={option.id}
          key={`button-${option.id}`}
          loading={loading}
          name={option.label}
          sendUserResponse={sendUserResponse}
          step={step}
        />
      ))}
    </VStack>
  );
};

export default ActionList;
