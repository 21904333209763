import { HStack, Text } from "@chakra-ui/react";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { FiThumbsDown, FiThumbsUp } from "react-icons/fi";

import VolunteerButton from "../../../button/VolunteerButton";
import { StatemachineContext } from "../../contexts/StatemachineContext";
import { ActionProps } from "../Action";
import ActionMessage from "../ActionMessage";

import HelpedQueryButton from "./HelpedQueryButton";

const ActionHelpedQuery = ({
  loading,
  sendUserResponse,
  step,
}: ActionProps) => {
  const { t } = useTranslation();
  const { onDiscussResult } = useContext(StatemachineContext);

  return (
    <>
      <ActionMessage text={t("shared:statemachine.helpedQuery")} />
      <HStack
        gap={3}
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "start",
        }}
      >
        <HelpedQueryButton
          active={step.attributes.answer.value == false}
          icon={FiThumbsDown}
          loading={loading}
          name="Nee"
          sendUserResponse={sendUserResponse}
          value={false}
        />
        <HelpedQueryButton
          active={step.attributes.answer.value == true}
          icon={FiThumbsUp}
          loading={loading}
          name="Ja"
          sendUserResponse={sendUserResponse}
          value={true}
        />
      </HStack>
      {onDiscussResult && (
        <>
          <Text>{t("shared:statemachine.discussWithVolunteer")}</Text>
          <VolunteerButton
            text={t("shared:statemachine.discussWithVolunteerButton")}
            onClick={onDiscussResult}
          />
        </>
      )}
    </>
  );
};

export default ActionHelpedQuery;
