import { SimpleGrid } from "@chakra-ui/react";
import React, { useContext } from "react";

import {
  Context,
  StatemachineContext,
} from "../../contexts/StatemachineContext";
import { ActionProps } from "../Action";

import PensionProviderButton from "./PensionProviderButton";

const TWO_COLUMNS = 2;

const ActionPensionProvider = ({
  loading,
  sendUserResponse,
  step,
}: ActionProps) => {
  const { context } = useContext(StatemachineContext);
  const columns =
    context === Context.QuestionFlow ? TWO_COLUMNS : { base: 2, sm: 3, md: 4 };

  return (
    <SimpleGrid
      columns={columns}
      gap={{ base: "0.5em", md: "1em" }}
      overflow="auto"
    >
      {step.attributes.node_answer_buttons.map((option) => (
        <PensionProviderButton
          key={`button-${option.id}`}
          loading={loading}
          name={option.label}
          provider={option.value as string}
          sendUserResponse={sendUserResponse}
          step={step}
        />
      ))}
    </SimpleGrid>
  );
};

export default ActionPensionProvider;
