import { FormControl, Text, Textarea, chakra } from "@chakra-ui/react";
import React, { ChangeEvent, FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "../../../button";
import { ActionProps } from "../Action";
import ActionMessage from "../ActionMessage";

import { ACTION_INPUT_TID } from "./ActionInput";

const ActionFeedback = ({ loading, sendUserResponse, step }: ActionProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(
    step.attributes.answer.value?.toString() ?? ""
  );
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    sendUserResponse(value);
  };
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    setValue(e.target.value);
  };

  return (
    <chakra.form width="100%" onSubmit={handleSubmit}>
      <ActionMessage text={t("shared:statemachine.feedback.header")} />
      <Text marginTop="1em">{t("shared:statemachine.feedback.body")}</Text>
      <FormControl paddingY={3}>
        <Textarea
          data-testid={ACTION_INPUT_TID}
          placeholder={t("shared:statemachine.feedback.placeholder")!}
          value={value}
          onChange={handleChange}
        />
      </FormControl>
      <Button
        float="right"
        isDisabled={loading}
        minWidth="60px"
        type="submit"
        value="Submit"
      >
        {t("shared:statemachine.confirm")}
      </Button>
    </chakra.form>
  );
};

export default ActionFeedback;
