import {
  Button,
  ResponsiveValue,
  Text,
  VStack,
  chakra,
} from "@chakra-ui/react";
import { Property } from "csstype";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { FaCheck } from "react-icons/fa";
import { FiMessageCircle } from "react-icons/fi";

import { combineReferrers } from "../../../../hooks/useCombinedReferer";
import { QuestionFlowContext } from "../../../../lib/questionFlowContext";
import { StatemachineContext } from "../../contexts/StatemachineContext";
import useStartJourney from "../../hooks/useStartJourney";
import { JourneyTrigger } from "../../types";
import { ActionProps } from "../Action";
import ActionMessage from "../ActionMessage";

export const RECOMMEND_BUTTON_TID = "RecommendButton";
export const RECOMMEND_OTHER_BUTTON_TID = "RecommendOtherButton";

const buttonAttrs = {
  height: "auto",
  justifyContent: "flex-start",
  lineHeight: "base",
  minHeight: 10,
  mt: 1,
  textAlign: "left" as Property.TextAlign,
  variant: "outline",
  whiteSpace: "normal" as ResponsiveValue<Property.WhiteSpace>,
  width: "100%",
};

type RecommendButtonProps = {
  buttonText: string;
  id: number;
};

const RecommendButton = ({ buttonText, id }: RecommendButtonProps) => {
  const [startConversation, loading] = useStartJourney(id);

  return (
    <Button
      {...buttonAttrs}
      data-testid={RECOMMEND_BUTTON_TID}
      isDisabled={loading}
      onClick={startConversation(JourneyTrigger.Recommended)}
    >
      {buttonText}
    </Button>
  );
};

const ActionRecommend = ({ step }: ActionProps) => {
  const { t } = useTranslation();
  const { journeyReferer, wizardId } = useContext(StatemachineContext);
  const { openFlow, resetFlow } = useContext(QuestionFlowContext);

  const recommendedWizards = step.attributes.recommended_wizards ?? [];
  const combinedReferer = combineReferrers(
    journeyReferer,
    `previous_wz_${wizardId}`
  );

  const handleNewQuestion = () => {
    resetFlow();
    openFlow({ referer: combinedReferer, restart: true });
  };

  return (
    <VStack alignItems="start" width="100%">
      <Text alignItems="center" color="green" display="flex">
        <chakra.span pr={1}>
          <FaCheck />
        </chakra.span>
        {t("shared:statemachine.ThanksForFeedback")}
      </Text>
      {recommendedWizards.length > 0 && (
        <ActionMessage text={t("shared:statemachine.mightBeInteresting")} />
      )}
      {recommendedWizards.map((recommendedStatemachine) => (
        <RecommendButton
          buttonText={recommendedStatemachine.question}
          id={recommendedStatemachine.id}
          key={recommendedStatemachine.id}
        />
      ))}
      <Text>{t("shared:statemachine.askAnotherQuestionHeader")}</Text>
      <Button
        {...buttonAttrs}
        data-testid={RECOMMEND_OTHER_BUTTON_TID}
        leftIcon={<FiMessageCircle />}
        onClick={handleNewQuestion}
      >
        {t("shared:statemachine.askAnotherQuestion")}
      </Button>
    </VStack>
  );
};

export default ActionRecommend;
