import { Box } from "@chakra-ui/react";
import React, { FC, useContext } from "react";

import { StepResource } from "../../../../types/serializers";
import SimpleMarkdown from "../../simpleMarkdown";
import { StatemachineContext } from "../contexts/StatemachineContext";
import useLinkTransformer from "../hooks/useLinkTransformer";

const Message: FC<{ step: StepResource }> = ({ step }) => {
  const { wizardId } = useContext(StatemachineContext);
  const transformLinkUri = useLinkTransformer(`wz_text_${wizardId}`);

  if (!step.attributes.prelude) {
    return null;
  }

  return (
    <Box width="100%">
      <SimpleMarkdown transformLinkUri={transformLinkUri}>
        {step.attributes.prelude}
      </SimpleMarkdown>
    </Box>
  );
};

export default Message;
