import { Stack, Text } from "@chakra-ui/react";
import React, { FC } from "react";

import Button from "./index";

interface VolunteerButtonProps {
  onClick: () => void;
  text: string;
}

const VolunteerButton: FC<VolunteerButtonProps> = ({ text, onClick }) => {
  return (
    <Stack alignItems="center" spacing={0}>
      <Text>
        Je kunt vanaf 23 augustus helaas geen vragen meer stellen aan onze
        vrijwilligers
      </Text>
      <Button
        _hover={{}}
        marginY="0.2em"
        variant="outline"
        visibility="hidden"
        width="100%"
        onClick={onClick}
      >
        {text}
      </Button>
    </Stack>
  );
};

export default VolunteerButton;
