import { JsonObject } from "../../../types/serializers";

export enum AnswerType {
  String = "string",
  Number = "number",
  Money = "money",
  Date = "date",
  Buttons = "buttons",
  HelpedQuery = "helped_query",
  Feedback = "feedback",
  PensionProvider = "pension_provider",
  Recommend = "recommend",
}

export interface NodeAnswerButton extends JsonObject {
  bd_checker_url?: string;
  id: number;
  label: string;
  order: number;
  value?: boolean | string | number | null;
}

export enum JourneyTrigger {
  Initial = "initial",
  Recommended = "recommended",
  Detour = "detour",
}
