import {
  FormControl,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  chakra,
} from "@chakra-ui/react";
import React, { ChangeEvent, FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "../../../button";
import { ActionProps } from "../Action";

import { ACTION_INPUT_TID } from "./ActionInput";

const ActionMoney = ({ sendUserResponse, loading, step }: ActionProps) => {
  const { t } = useTranslation();
  const initialAmount = parseFloat(
    step.attributes.answer.value?.toString() ?? ""
  );
  const [value, setValue] = useState(initialAmount ? initialAmount / 100 : "");
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    sendUserResponse(Math.round(parseFloat(value.toString()) * 100));
  };
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setValue(e.target.value);
  };

  return (
    <chakra.form width="100%" onSubmit={handleSubmit}>
      <Stack alignItems="baseline" flexDirection="row">
        <FormControl pr={3}>
          <InputGroup>
            <InputLeftElement
              color="gray.400"
              fontSize="1.2em"
              pointerEvents="none"
            >
              €
            </InputLeftElement>
            <Input
              data-testid={ACTION_INPUT_TID}
              step=".01"
              type="number"
              value={value}
              onChange={handleChange}
            />
          </InputGroup>
        </FormControl>
        <Button
          isDisabled={loading}
          minWidth="60px"
          type="submit"
          value="Submit"
        >
          {t("shared:statemachine.confirm")}
        </Button>
      </Stack>
    </chakra.form>
  );
};

export default ActionMoney;
