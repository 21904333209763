import React, {
  PropsWithChildren,
  createContext,
  useEffect,
  useState,
} from "react";

import { JourneyResource, StepResource } from "../../../../types/serializers";
import useCurrentUser from "../../../hooks/useCurrentUser";
import useResources from "../../../hooks/useResources";
import useStoredState from "../../../hooks/useStoredState";
import useCurrentJourney from "../lib/useCurrentJourney";
import useWizard from "../lib/useWizard";

const SESSION_ID_KEY = "stm-session-id";

interface StatemachineContext {
  buttonSubText?: string;
  buttonText?: string;
  context: Context;
  currentJourney?: JourneyResource;
  error?: string;
  getStep: (index: number) => StepResource | undefined;
  journeyReferer?: string;
  loading: boolean;
  onDiscussResult?: () => void;
  onNewJourney?: (newJourney: JourneyResource) => void;
  sessionId?: string;
  setCurrentJourneyId: (id: string) => void;
  setError: React.Dispatch<React.SetStateAction<string | undefined>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setSessionId: (id: string) => void;
  tagline?: string;
  testMode?: boolean;
  wizardId?: number;
}

export const StatemachineContext = createContext<StatemachineContext>(
  {} as StatemachineContext
);

export enum Context {
  FullPage = "full_page",
  QuestionFlow = "question_flow",
  RichText = "rich_text",
  FrequentlyAsked = "frequently_asked",
}

export interface StatemachineProps extends PropsWithChildren {
  buttonSubText?: string;
  buttonText?: string;
  context: Context;
  id?: number;
  initialJourneyId?: string | null;
  onDiscussResult?: () => void;
  onNewJourney?: (newJourney: JourneyResource) => void;
  referer?: string;
  tagline?: string;
  testMode?: boolean;
}

export const StatemachineContextProvider = ({
  buttonSubText,
  buttonText,
  children,
  context,
  id,
  initialJourneyId,
  referer,
  tagline,
  testMode,
  onDiscussResult,
  onNewJourney,
}: StatemachineProps) => {
  const [currentUser] = useCurrentUser();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [wizard, wizardLoading, wizardError] = useWizard(id);
  const [currentJourney, setCurrentJourneyId, journeyLoading, journeyError] =
    useCurrentJourney(initialJourneyId);
  const [sessionId, setSessionId] = useStoredState(SESSION_ID_KEY, {
    persist: true,
  });
  const journeySteps = useResources(
    currentJourney?.relationships.current_steps.data,
    { root: GLOBALS.statemachineRoot }
  );
  const journeySessionId = currentJourney?.attributes.session_id;
  useEffect(() => {
    if (journeySessionId && journeySessionId !== sessionId) {
      setSessionId(journeySessionId);
    }
  }, [journeySessionId]);
  const steps = currentJourney?.relationships.current_steps.data ?? [];
  const getStep = (index: number) =>
    journeySteps?.find(
      (record) =>
        record?.id === steps[index]?.id && record?.type === steps[index]?.type
    );

  const wizardId = currentJourney?.attributes.wizard_id ?? id;

  const value = {
    buttonSubText,
    buttonText,
    context,
    currentJourney,
    error: wizardError ?? journeyError ?? error,
    getStep,
    journeyReferer: referer,
    loading: loading || journeyLoading || wizardLoading,
    onDiscussResult,
    onNewJourney,
    sessionId,
    setCurrentJourneyId,
    setError,
    setLoading,
    setSessionId,
    tagline: (tagline || wizard?.attributes.question) ?? "\u00A0",
    testMode: testMode || currentUser?.attributes.admin,
    wizardId,
  };

  return (
    <StatemachineContext.Provider value={value}>
      {children}
    </StatemachineContext.Provider>
  );
};

export default StatemachineContextProvider;
