import React from "react";

import Button from "../../../button";
import PensionProvider from "../../../pensionProvider";
import { PensionProviders } from "../../../richText/lib/elements/pensionProviders";
import { ActionProps } from "../Action";

interface Props extends ActionProps {
  active?: boolean;
  name: string;
  provider: string;
}

const PensionProviderButton = ({
  active,
  name,
  loading,
  provider,
  sendUserResponse,
}: Props) => {
  const buttonHandler = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    sendUserResponse(provider);
  };

  if (Object.keys(PensionProviders).includes(provider)) {
    return (
      <PensionProvider
        borderColor="primary.600"
        marginBottom={0}
        padding={{ base: "0.5em", md: "1em" }}
        provider={provider}
        onClick={buttonHandler}
      />
    );
  }

  return (
    <Button
      gridColumn="1 / -1"
      height="auto"
      isDisabled={loading}
      justifyContent="flex-start"
      lineHeight="base"
      minHeight={10}
      variant={active ? "solid" : "outline"}
      whiteSpace="normal"
      width="100%"
      onClick={buttonHandler}
    >
      {name}
    </Button>
  );
};

export default PensionProviderButton;
