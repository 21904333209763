import { Alert, AlertIcon } from "@chakra-ui/react";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { StepResource } from "../../../../types/serializers";
import useUpdateCallback from "../../../hooks/useUpdateCallback";
import { AnswerType } from "../types";

import ActionMessage from "./ActionMessage";
import ActionDate from "./Actions/ActionDate";
import ActionFeedback from "./Actions/ActionFeedback";
import ActionHelpedQuery from "./Actions/ActionHelpedQuery";
import ActionInput from "./Actions/ActionInput";
import ActionList from "./Actions/ActionList";
import ActionMoney from "./Actions/ActionMoney";
import ActionPensionProvider from "./Actions/ActionPensionProvider";
import ActionRecommend from "./Actions/ActionRecommend";

const RENDERERS: {
  [key in AnswerType]: (actionProps: ActionProps) => JSX.Element;
} = {
  [AnswerType.String]: (actionProps) => (
    <ActionInput {...actionProps} inputType="text" />
  ),
  [AnswerType.Number]: (actionProps) => (
    <ActionInput {...actionProps} inputType="number" />
  ),
  [AnswerType.Money]: ActionMoney,
  [AnswerType.Date]: ActionDate,
  [AnswerType.Buttons]: ActionList,
  [AnswerType.HelpedQuery]: ActionHelpedQuery,
  [AnswerType.Feedback]: ActionFeedback,
  [AnswerType.Recommend]: ActionRecommend,
  [AnswerType.PensionProvider]: ActionPensionProvider,
};

export type SendUserResponse = (value: string | number | boolean) => void;

export interface ActionProps {
  loading?: boolean;
  sendUserResponse: SendUserResponse;
  step: StepResource;
}

const Action: FC<{ onSuccess?: () => void; step: StepResource }> = ({
  step,
  onSuccess,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [updateCallback] = useUpdateCallback(
    { id: step.id, type: step.type },
    GLOBALS.statemachineRoot
  );

  const handleError = () => {
    setError(t("shared:statemachine.errorMessage")!);
    setLoading(false);
  };
  const handleSuccess = () => {
    if (onSuccess) {
      onSuccess();
    }

    setLoading(false);
  };
  const sendUserResponse = async (value: string | number | boolean) => {
    setError(null);
    setLoading(true);
    const params = {
      answer: {
        type: step.attributes?.answer_type,
        value: value,
      },
    };
    updateCallback(params, handleSuccess, handleError);
  };
  const actionProps = {
    loading,
    sendUserResponse,
    step,
  };

  const Renderer = RENDERERS[step.attributes.answer_type as AnswerType];

  if (!Renderer) {
    throw Error(`Unexpected type: ${step.attributes.answer_type}`);
  }

  return (
    <>
      <ActionMessage text={step.attributes.question} />
      <Renderer {...actionProps} />
      {error && (
        <Alert borderRadius={8} mt={4} status="error">
          <AlertIcon />
          {error}
        </Alert>
      )}
    </>
  );
};

export default Action;
