import React, { FC } from "react";

import { Prediction } from "../../types/serializers";
import { Context } from "../components/statemachine/contexts/StatemachineContext";

export const MIN_QUESTION_LENGTH = 15;

export interface OpenFlowOpts {
  context?: Context;
  referer: string;
  restart?: boolean;
  wizardId?: string;
}

export interface QuestionFlowContext {
  closeFlow: (reset?: boolean) => void;
  currentWizardId?: string;
  email: string;
  error?: string;
  footerEnabled: boolean;
  forwardDisabled?: boolean;
  forwardedChatConversationId?: string;
  initialQuestion: string;
  moveBackwards: <T>(step?: FC<T>, stepProps?: T) => void;
  moveForwards: <T>(step: FC<T>, stepProps?: T) => void;
  onBackwards: (() => void) | undefined;
  onForwards: (() => void) | undefined;
  open: boolean;
  openFlow: (opts: OpenFlowOpts) => void;
  questionFlowId?: string;
  recommendedWizards: Prediction[];
  referer?: string;
  renderAsForm: boolean;
  resetFlow: () => void;
  setCurrentWizardId: (id?: string) => void;
  setEmail: (email: string) => void;
  setError: (error?: string) => void;
  setFooterEnabled: (enabled: boolean) => void;
  setForwardDisabled: (disabled?: boolean) => void;
  setForwardedChatConversationId: (id?: string) => void;
  setInitialQuestion: (question: string) => void;
  setOnBackwards: (callback: (() => void) | undefined) => void;
  setOnForwards: (callback: (() => void) | undefined) => void;
  setQuestionFlowId: (id?: string) => void;
  setRecommendedWizards: (wizards: Prediction[]) => void;
  setRenderAsForm: (renderAsForm: boolean) => void;
  setShowFloat: (show: boolean) => void;
  setVolunteerQuestion: (question: string) => void;
  setWizardActive: (active: boolean) => void;
  showFloat: boolean;
  statemachineContext: Context;
  volunteerQuestion: string;
  widget?: boolean;
  wizardActive: boolean;
}

export const QuestionFlowContext = React.createContext<QuestionFlowContext>(
  {} as QuestionFlowContext
);
