import { Box } from "@chakra-ui/react";
import React, { useContext } from "react";

import SimpleMarkdown from "../../simpleMarkdown";
import { StatemachineContext } from "../contexts/StatemachineContext";
import useLinkTransformer from "../hooks/useLinkTransformer";

type Props = {
  text?: string | null;
};

const ActionMessage = ({ text }: Props) => {
  const { wizardId } = useContext(StatemachineContext);
  const transformLinkUri = useLinkTransformer(`wz_text_${wizardId}`);

  if (!text) {
    return null;
  }

  return (
    <Box fontWeight="bold">
      <SimpleMarkdown transformLinkUri={transformLinkUri}>
        {text}
      </SimpleMarkdown>
    </Box>
  );
};

export default ActionMessage;
