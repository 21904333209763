import { t } from "i18next";
import { useState } from "react";

import { JourneyResource, ResourceType } from "../../../../types/serializers";
import useResource from "../../../hooks/useResource";
import useStatus from "../../../hooks/useStatus";
import { isLoadingStatus } from "../../../lib/status";
import { ResourceStatus } from "../../store/Store";

const useCurrentJourney = (
  initialJourneyId: string | null | undefined
): [
  journey: JourneyResource | undefined,
  setCurrentJourneyId: (id: string) => void,
  loading: boolean,
  error?: string
] => {
  const [currentJourneyId, setCurrentJourneyId] = useState(initialJourneyId);

  const currentJourney = useResource(
    currentJourneyId
      ? { id: currentJourneyId, type: ResourceType.Journey }
      : undefined,
    { root: GLOBALS.statemachineRoot }
  );
  const status = useStatus(
    currentJourneyId
      ? { id: currentJourneyId, type: ResourceType.Journey }
      : undefined,
    { root: GLOBALS.statemachineRoot }
  );
  const loading = !!currentJourneyId && isLoadingStatus(status);
  const error = [
    ResourceStatus.Error,
    ResourceStatus.Forbidden,
    ResourceStatus.NotFound,
  ].includes(status)
    ? t("shared:statemachine.errorMessage")
    : undefined;

  return [currentJourney, setCurrentJourneyId, loading, error];
};

export default useCurrentJourney;
