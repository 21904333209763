import { Box } from "@chakra-ui/react";
import React, { useEffect } from "react";

const checkerScript =
  "https://vuecomponent.cloudseven.nl/web-component/latest/index.js";

const BDChecker = ({
  label,
  onFinish,
  url,
}: {
  label: string;
  onFinish: (value: string) => void;
  url: string;
}) => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = checkerScript;
    script.async = true;
    script.type = "module";

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  React.useEffect(() => {
    const listener = (e: any) => {
      if (e.detail?.data) {
        onFinish(e.detail.data.result);
      }
    };

    window.addEventListener("bdCheckerClose", listener);

    return () => {
      window.removeEventListener("bdCheckerClose", listener);
    };
  }, []);

  return (
    <Box textAlign="center">
      {React.createElement("de-belastingdienst", {
        alt: label,
        "json-url": url,
        "log-level": "log",
      })}
    </Box>
  );
};

export default BDChecker;
