import { t } from "i18next";

import { ResourceType, WizardResource } from "../../../../types/serializers";
import useResource from "../../../hooks/useResource";
import useStatus from "../../../hooks/useStatus";
import { isLoadingStatus } from "../../../lib/status";
import { ResourceStatus } from "../../store/Store";

const useWizard = (
  id?: number
): [wizard: WizardResource | undefined, loading: boolean, error?: string] => {
  const wizard = useResource(
    id ? { id: id.toString(), type: ResourceType.Wizard } : undefined,
    { root: GLOBALS.statemachineRoot }
  );
  const status = useStatus(
    id ? { id: id.toString(), type: ResourceType.Wizard } : undefined,
    { root: GLOBALS.statemachineRoot }
  );
  const loading = !!id && isLoadingStatus(status);
  let error = undefined;
  if (status === ResourceStatus.NotFound) {
    error = t("shared:statemachine.notFoundMessage");
  } else if (
    [ResourceStatus.Error, ResourceStatus.Forbidden].includes(status)
  ) {
    error = t("shared:statemachine.errorMessage");
  }

  return [wizard, loading, error];
};

export default useWizard;
