import React, { FC } from "react";

import Button from "../../../button";
import { ActionProps } from "../Action";

interface HelpedQueryButtonProps extends Omit<ActionProps, "step"> {
  active?: boolean;
  icon: FC;
  name: string;
  value: boolean;
}

const HelpedQueryButton = ({
  active,
  icon: Icon,
  value,
  name,
  sendUserResponse,
  loading,
}: HelpedQueryButtonProps) => {
  const buttonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    sendUserResponse(value);
  };

  const backgroundColor = value ? "status.success" : "status.error";
  const hoverBackgroundColor = value ? "#49814E" : "#964D4D";

  return (
    <Button
      _hover={{ bg: hoverBackgroundColor }}
      bg={backgroundColor}
      border={active ? "2px solid" : undefined}
      borderColor={active ? hoverBackgroundColor : undefined}
      color="blackish"
      isDisabled={loading}
      justifyContent="flex-start"
      leftIcon={<Icon />}
      marginBottom="0.5em"
      minHeight="38px"
      textAlign="left"
      onClick={buttonHandler}
    >
      {name}
    </Button>
  );
};

export default HelpedQueryButton;
