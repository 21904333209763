import { FormControl, Input, Stack, chakra } from "@chakra-ui/react";
import React, { ChangeEvent, FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "../../../button";
import { ActionProps } from "../Action";

export const ACTION_DATE_TID = "ActionDate";

const parseDate = (rawDate: string) => {
  try {
    const d = Date.parse(rawDate);
    const ye = new Intl.DateTimeFormat("nl", { year: "numeric" }).format(d);
    const mo = new Intl.DateTimeFormat("nl", { month: "2-digit" }).format(d);
    const da = new Intl.DateTimeFormat("nl", { day: "2-digit" }).format(d);

    return `${da}-${mo}-${ye}`;
  } catch {
    return null;
  }
};

const ActionDate = ({ sendUserResponse, loading, step }: ActionProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(
    step.attributes.answer.value?.toString() ?? ""
  );
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const date = parseDate(value);
    if (date) {
      sendUserResponse(date);
    }
  };
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setValue(e.target.value);
  };

  return (
    <chakra.form width="100%" onSubmit={handleSubmit}>
      <Stack alignItems="baseline" flexDirection="row">
        <FormControl pr={3}>
          <Input
            data-testid={ACTION_DATE_TID}
            type="date"
            value={value}
            onChange={handleChange}
          />
        </FormControl>
        <Button
          isDisabled={loading || value.length === 0}
          type="submit"
          value="Submit"
        >
          {t("shared:statemachine.confirm")}
        </Button>
      </Stack>
    </chakra.form>
  );
};

export default ActionDate;
